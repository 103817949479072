import React from "react"
import  ContainerWrapper from "./style"


const Container = ({
  children,
  className,
  fullWidth,
  mobilegutter,
  nogutter,
  width,
  id,
}) => {
  const addAllClasses = ["container"]

  if (className) {
    addAllClasses.push(className)
  }

  return (
    <ContainerWrapper
      id={id}
      className={addAllClasses.join(" ")}
      fullWidth={fullWidth}
      nogutter={nogutter}
      mobilegutter={mobilegutter}
      width={width}
    >
      {children}
    </ContainerWrapper>
  )
}


export default Container;
