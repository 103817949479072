import React from "react"
import { SocialProfileWrapper, SocialProfileItem } from "./style"

const SocialProfile = ({ items, className }) => {
  const addAllClasses = ["social_profiles"]

  if (className) {
    addAllClasses.push(className)
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(" ")}>
      {items.map((item, index) => (
        <a href={item.url || "#"} aria-label="social icon" target='_blank' rel="noopener" key={`social-item-${index}`}>
          <SocialProfileItem
            
            className="social_profile_item"
          >
            <img src={item.logo.publicURL} alt="" />
          </SocialProfileItem>
        </a>
      ))}
    </SocialProfileWrapper>
  )
}

export default SocialProfile
