import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import NavbarWrapper from "../../../components/Navbar"
import Drawer from "../../../components/Drawer"
import Button from "../../../components/Button"
import Logo from "../../../components/Logo"
import Box from "../../../components/Box"
import HamburgMenu from "../../../components/HamburgMenu"
import Container from "../../../components/Container"
import { DrawerContext } from "../../../contexts/DrawerContext"
import ScrollSpyMenu from "../../../components/ScrollSpyMenu"
import { Link } from 'gatsby';
import LogoImage from "../../../assets/images/generic/Logo.png"

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { state, dispatch } = useContext(DrawerContext)

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    })
  }

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        MENU_ITEMS {
          label
          path
          offset
          staticLink
        }
        REGISTER_BTN
      }
    }
  `)

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Agrabiz"
            logoStyle={logoStyle}
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.dataJson.MENU_ITEMS}
              offset={-70}
            />

            <Link className="navbar_button" to="/register">
              <Button {...button} title={Data.dataJson.REGISTER_BTN} />
            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#98e044" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.dataJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <Link className="navbar_button" to="/register">
                <Button {...button} title={Data.dataJson.REGISTER_BTN} />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  )
}

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
}

Navbar.defaultProps = {
  navbarStyle: {
    className: "hosting_navbar",
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["180px", "190px"],
  },
  button: {
    type: "button",
    fontSize: "15px",
    fontWeight: "600",
    color: "white",
    borderRadius: "8px",
    pl: "15px",
    pr: "15px",
    backgroundColor: "#98e044",
    minHeight: "auto",
    height: `${5}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
}

export default Navbar
