import styled from "styled-components"

export const SocialProfileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const SocialProfileItem = styled.div`
  width: 50px;
  @media (max-width: 768px) {
    width: 45px;
  }

  &:hover {
    a {
      color: #84da2c;
    }
    img {
      filter: grayscale(0);
    }
  }
  a {
    color: #bbbbbb;
    transition: 0.15s ease-in-out;
  }

  img {
    filter: grayscale(1);
    transition: 0.3s ease-in-out;
    margin-left: 1.5px;
    margin-right: 1.5px;
  }
`
