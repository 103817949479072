const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#b5b5b6",
  textColor: "#5d646d", // 3
  labelColor: "#767676", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#98e044",
  primaryHover: "#ff7333", // 9
  secondary: "#ff5b60", // 10
  secondaryHover: "#FF282F", // 11
  yellow: "#fdb32a", // 12
  yellowHover: "#F29E02", // 13
  primaryBoxShadow: "0px 8px 20px -6px rgba(235,77,75,0.6)",
  btntext:"#f9f9f9",
  orange:'#ff7333',
  gray : '#b5b5b6'
}

export default colors
