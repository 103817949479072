import styled from "styled-components"

const FooterWrapper = styled.section`
  padding: 80px 0 55px;
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid #d8dcde;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media (max-width: 768px) and (min-width: 200px) {
    align-content: center;
    /* margin-left: 30px; */
  }
  .flex-b1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media (max-width: 768px) and (min-width: 200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: flex-end;
    }
  }
  .flex-width-up {
    flex-direction:columns;
    @media (max-width: 768px) and (min-width: 200px) {
      margin-top: 3%;
    }
  }

  .flex-width {
    @media (max-width: 768px) and (min-width: 200px) {
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: flex-end;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 33%;
  }
  .flex-width-b2 {
    @media (max-width: 768px) and (min-width: 200px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  .text-style {
    width: 100%;
    color: "#b5b5b5";
    color: "#20201d";
    object-fit: contain;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b5b5b5;
  }
  .text-margin {
    margin-left: 30px;
  }
  .flex-block-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    @media (max-width: 768px) and (min-width: 200px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  .flexEnd {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
    @media (max-width: 768px) and (min-width: 200px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
`

export default FooterWrapper
