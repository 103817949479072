import styled from "styled-components"

const CookiesWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  @media (max-width: 1200px) {
    #banner {
      width: 100%;
      flex-direction: column;
    }
  }
  .column-space-arround-center {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .column-space-arround {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    margin-top: 33px;
    @media (max-width: 425px) {
      margin-left: 13%;
      margin-right: 13%;
    }
    @media (max-width: 375px) {
      margin-left: 10.5%;
      margin-right: 10.5%;
    }
  }
  .span-style {
    padding-bottom: 4px;
    @media (max-width: 1200px) {
      display: flex;
      text-align: center;
      justify-content: center;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      margin-bottom:2%
    }
    @media (max-width: 375px) {
      margin-bottom:2%
    }
    @media (max-width: 320px) {
      margin-bottom:5%
    }
  }

  @media (min-width: 768px) {
    #banner {
      display: flex;
      justify-content: space-around;
      z-index: 9999999;
      width: 100%;
      position: absolute;
      background-color: #c7c7c7;
      color: white;
      font-size: 15px;
      margin-bottom: 5% !important;
      padding: 1px;
      height: 111px;
      top: 0px;
    }
  }

  @media (max-width: 767px) and (min-width: 200px) {
    #banner {
      background-color: #c7c7c7;
      justify-content: space-around;
      z-index: 9999999;
      width: 100%;
      position: absolute;
      background-color: #c7c7c7;
      color: white;
      font-size: 11px;
      padding: 1px;
      top: 0px;
    }
  }
  @media (max-width: 1200px) and (min-width: 767px) {
    #banner {
      height: 118px;
      font-size: 13px;
    }
  }

  #banner .accept {
    color: #98e044;
    height: 32px;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 3px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-sizing: border-box;
    font-size: 15px;
    color: #ffffff;
    background-color: #98e044;
    border-radius: 8px;
    margin-left: 20px;
  }

  #banner .reject {
    display: flex;
    height: 32px;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 3px;
    font-family: inherit;
    font-weight: 500;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-sizing: border-box;
    font-size: 15px;
    color: #98e044;
    background-color: #ffffff;
    border-radius: 8px;
    margin-left: 20px;
  }
`
export default CookiesWrapper
