import React from 'react';
import { withCookies } from 'react-cookie';
import CookiesWrapper from "./style"
import { Link } from 'gatsby';
import CookieBanner from 'react-cookie-banner';

const Cookies = () => {



    return (
      <CookiesWrapper id="benefits">
            <CookieBanner
              position='bottom'
              message="Agrabiz uses cookies to facilitate its use."
              cookie="user-has-accepted-cookies"
              buttonMessage="Accept Cookies"
              dismissOnScroll={false}
              link={<Link to="/">Learn more about cookies.</Link>}
            >
              {onAccept => (
                <div id="banner">
                  <p className="column-space-arround">
                    <span className="span-style">
                      <b>
                        Wir verwenden Cookies auf dieser Seite zur Verbesserung
                        Ihrer User Experience
                      </b>
                    </span>
                    <span className="span-style">
                      Mit dem Nutzen dieser Seite durch Scrollen oder Klicken
                      unserer Inhalte stimmen Sie der Verwendung dieser Cookies
                      zu
                    </span>
                  </p>
                  <div className="flex-center">
                    <button
                      className="accept"
                      onClick={e => {
                        onAccept();
                      }}
                    >
                      Cookies akzeptieren
                    </button>
                    <Link
                    to="/cookie-richtlinie"
                    className="reject"
                    > 
                      Mehr erfahren
                      </Link>
                  </div>
                </div>
              )}
            </CookieBanner>
            </CookiesWrapper>
    );
}

export default withCookies(Cookies);
