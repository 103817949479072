import colors from "./colors"

export const theme = {
  breakpoints: [576, 768, 991, 1220],
  space: [0, 5, 8, 10, 15, 20, 25, 30, 33, 35, 40, 50, 60, 70, 80, 85, 90, 100],
  fontSizes: [10, 12, 14, 15, 16, 18, 20, 22, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  borders: [
    0,
    "1px solid",
    "2px solid",
    "3px solid",
    "4px solid",
    "5px solid",
    "6px solid",
  ],
  radius: [3, 4, 5, 10, 20, 30, 60, 120, "50%"],
  widths: [36, 40, 44, 48, 54, 70, 81, 128, 256],
  heights: [36, 40, 44, 46, 48, 54, 70, 81, 128],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors,
  colorStyles: {
    primary: {
      color: colors.primary,
      border: "1px solid",
      borderColor: colors.primary,
      backgroundColor: colors.transparent,
      "&:hover": {
        color: colors.white,
        backgroundColor: colors.primaryHover,
        borderColor: colors.primaryHover,
        boxShadow: colors.primaryBoxShadow,
      },
    },
    secondary: {
      color: colors.secondary,
      borderColor: colors.secondary,
      "&:hover": {
        color: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
    warning: {
      color: colors.yellow,
      borderColor: colors.yellow,
      "&:hover": {
        color: colors.yellowHover,
        borderColor: colors.yellowHover,
      },
    },
    error: {
      color: colors.secondaryHover,
      borderColor: colors.secondaryHover,
      "&:hover": {
        color: colors.secondary,
        borderColor: colors.secondary,
      },
    },
    primaryWithBg: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      "&:hover": {
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        boxShadow: colors.headingColor,
      },
    },
    secondaryWithBg: {
      color: colors.white,
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
      "&:hover": {
        backgroundColor: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
    warningWithBg: {
      color: colors.white,
      backgroundColor: colors.yellow,
      borderColor: colors.yellow,
      "&:hover": {
        backgroundColor: colors.yellowHover,
        borderColor: colors.yellowHover,
      },
    },
    errorWithBg: {
      color: colors.white,
      backgroundColor: colors.secondaryHover,
      borderColor: colors.secondaryHover,
      "&:hover": {
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
      },
    },
    transparentBg: {
      backgroundColor: colors.white,
      "&:hover": {
        backgroundColor: colors.white,
      },
    },
  },
  buttonStyles: {
    textButton: {
      border: 0,
      color: colors.btntext,
      padding: 0,
      width: "80%",
      height: "70px",
      borderRadius: "5px",
      backgroundColor: `${colors.btntext}`,
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
    },
    outlined: {
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: colors.transparent,
    },
    fab: {
      border: "0",
      width: "125px",
      height: "125px",
      padding: 0,
      borderRadius: "50%",
      backgroundColor: colors.orange,
      justifyContent: "center",
      "span.btn-icon": {
        paddingLeft: 0,
      },
    },
    fabNews: {
      border: "0",
      width: "40px",
      height: "40px",
      padding: 0,
      borderRadius: "50%",
      justifyContent: "center",
      "span.btn-icon": {
        paddingLeft: 0,
      },
    },

    extendedFab: {
      border: "0",
      width: "30%",
      height: "70px",
      borderRadius: "5px",
      justifyContent: "center",
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
    },

    videoBtn: {
      border: 0,
      color: colors.btntext,
      padding: 0,
      width: "80%",
      height: "70px",
      borderRadius: "5px",
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
      backgroundColor: `${colors.primary}`,
    },
    custumBtn: {
      margin: "12% 2% 2% 2% ",
      borderRadius: "10px",
      width: "27%",
      height: "70px",
      justifyContent: "center",
      backgroundColor: colors.white,
      color: colors.primary,
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
      fontWeight: "700",
    },
    download: {
      margin: "5% 0% -5% 2% ",
      borderRadius: "2%",
      justifyContent: "center",
      color: colors.btntext,
      padding: 0,
      width: "90%",
      height: "80px",
      backgroundColor: `${colors.btntext}`,
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
    },
    webmobileIcon: {
      border: "0",
      width: "25px",
      height: "25px",
      marginTop: "-10px",
      marginRight: "30px",
      borderRadius: "35%",
      backgroundColor: colors.white,
      justifyContent: "center",
      "span.btn-icon": {
        paddingLeft: 0,
      },
      boxShadow: "0.7px 5px 10px 0 rgba(194, 207, 224, 0.73);",
    },

    downloadSection: {
      border: "0",
      width: "75.5%",
      height: "70px",
      borderRadius: "10px",
      justifyContent: "center",
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
    },
    faq: {
      border: "0",
      width: "94.5%",
      height: "70px",
      borderRadius: "10px",
      justifyContent: "center",
      boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
    },
    x: {
      border: "0",
      width: "10px",
      height: "10px",
      position: "absolute",
      marginLeft: "-1.3%",
      borderRadius: "10%",
      backgroundColor: "transparent",
      justifyContent: "center",
      top: "-1%",
      right: "0.5%",
      "&:hover": {
        opacity: "0.7",
      },
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "2%",
    },
  },
}
