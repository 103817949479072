import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Text from "../../../components/Text"
import Button from "../../../components/Button"
import SocialProfile from "../../../components/SocialProfile"
import FooterWrapper from "./style"
import { Link } from "gatsby"
import up from "../../../assets/images/generic/up-arrow.png"

const Footer = ({ secDescription }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        FOOTER {
          id
          text
          url
        }
        SOCIAL_PROFILES {
          logo {
            publicURL
          }
          url
        }
        REGISTER_BTN
      }
    }
  `)

  return (
    <FooterWrapper>
      <div className="flex-b1">
        <div className="flex-width flex-block-text">
          <div>
            <Link to="/datenschutz">
              <Text
                content="Datenschutzerklärung"
                className="text-style"
                {...secDescription}
              />
            </Link>
          </div>
          <div>
            <Link to="/impressum">
              <Text
                content="Impressum"
                className="text-style"
                {...secDescription}
              />
            </Link>
          </div>
        </div>
        <div className="flex-width">
          <SocialProfile items={Data.dataJson.SOCIAL_PROFILES} />
        </div>
        <div className="flex-width flex-width-up">
          <Link to="/#">
            <Text
              content="Zurück zum Anfang"
              className="text-style"
              {...secDescription}
            />
          </Link>
          <Link to="/#">
            <Button
              variant="webmobileIcon"
              icon={
                  <img src={up} width="90%" alt="img" display="flex" />
              }
              margin="0 0 16px 25px "
            />
          </Link>
        </div>
      </div>
      <div></div>
      <div className="flex-b1">
        <div className="flex-width-b2 flexEnd text-style " {...secDescription}>
          © Made with 💚 in Munich
        </div>
      </div>
    </FooterWrapper>
  )
}

Footer.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  row: PropTypes.object,
  secDescription: PropTypes.object,
}

Footer.defaultProps = {
  secDescription: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "500",
    color: "#b6b6b7",
  },
}
export default Footer
